@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-track {
    background-color: #00000000;
    width: 10px;
  
  }
  
  ::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    background-color: #fafafa00;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  
    background-color: #3a3a3a7a;
    border: 0px solid #55555500;
  }